import constants from "./constants";

export default {
    almohadilla1: `${constants.route}/almohadilla1.png`,
    blusa1: `${constants.route}/blusa1.png`,
    bolso1: `${constants.route}/bolso1.png`,
    bolso2: `${constants.route}/bolso2.png`,
    cantimplora1: `${constants.route}/cantimplora1.png`,
    cartel1: `${constants.route}/cartel1.png`,
    cojin1: `${constants.route}/cojin1.png`,
    cojin2: `${constants.route}/cojin2.png`,
    copa1: `${constants.route}/copa1.png`,
    estuche1: `${constants.route}/estuche1.png`,
    fondo1: `${constants.route}/fondo1.png`,
    fondo2: `${constants.route}/fondo2.png`,
    fondo3: `${constants.route}/fondo3.png`,
    fondo4: `${constants.route}/fondo4.png`,
    gorra1: `${constants.route}/gorra1.png`,
    gorra2: `${constants.route}/gorra2.png`,
    llavero1: `${constants.route}/llavero1.png`,
    logoMariaAngelesmt: `${constants.route}/logo-mariaangelesmt.png`,
    logo: `${constants.route}/logo.png`,
    style1: `${constants.route}/style1.png`,
    style2: `${constants.route}/style2.png`,
    style3: `${constants.route}/style3.png`,
    style4: `${constants.route}/style4.png`,
    taza1: `${constants.route}/taza1.png`,
    taza2: `${constants.route}/taza2.png`,
    taza3: `${constants.route}/taza3.png`,
}