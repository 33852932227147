import React from 'react';
import './images.css';

const ImagesProducts = (props) => {
    return (
        <>
            <li className="images-item">               
                    <figure className="images-item-pic-wrap">
                        <img src={props.src} alt="Products" className="images-item-img" />
                    </figure>
            </li>
        </>
    )
}

export default ImagesProducts;
