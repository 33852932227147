import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { send } from 'emailjs-com';
import './contactUs.css';


const ContactUs = () => {

    const initialFormState = {
        formName: '',
        formNameError: false,
        email: '',
        emailError: false,
        emailError2: false,
        message: '',
        messageError: false,
        formValid: false,
        checkbox: false,
        checkboxError: false
    }

    const [formState, setFormState] = useState(initialFormState);

    const isValidEmail = (email) => {
        return /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
    };

    const handleBlur = (e) => {
        const name= e.target.name;
        const value= e.target.value;

        setFormState({ 
            ...formState,
            [name]: value 
        });

        if(name === 'formName') {
            if(value.length <= 0) {
                setFormState({ 
                    ...formState,
                    formNameError: true 
                })
            } else {
                setFormState({ 
                    ...formState,
                    formNameError: false 
                })
            }
        }

        if(name === 'email') {
            if(value.length <= 0) {
                setFormState({ 
                    ...formState,
                    emailError: true 
                })
            } else {
                setFormState({ 
                    ...formState,
                    emailError: false 
                });
                if(isValidEmail(value)){
                    setFormState({ 
                        ...formState,
                        emailError2: false 
                    })
                } else {
                    setFormState({ 
                        ...formState,
                        emailError2: true 
                    })
                }
            }
        }

        if(name === 'message') {
            if(value.length <= 0) {
                setFormState({ 
                    ...formState,
                    messageError: true 
                })
            } else {
                setFormState({ 
                    ...formState,
                    messageError: false 
                })
            }
        }
        if(name === 'checkbox') {
            if(!checkbox) {
                setFormState({ 
                    ...formState,
                    checkboxError: true 
                })
            } else {
                setFormState({ 
                    ...formState,
                    checkboxError: false 
                })
            }
        }
    }

    const handleChange = (e) => {
       setFormState({ 
           ...formState,
           [e.target.name]: e.target.name === 'checkbox' ? e.target.checked : e.target.value 
        });
    }


    const isValidForm = (formState) => {
       const { formName, email, message, formNameError, emailError, emailError2, messageError, checkbox, checkboxError } = formState;
     
       if(formName && email && message && checkbox && !formNameError && !emailError && !emailError2 && !messageError && !checkboxError) {
           return true;
       } else { 
           setFormState({
                ...formState, 
               formNameError: formName ? false : true,
               emailError: email ? false : true,
               messageError: message ? false : true,
               checkboxError: checkbox ? false : true,
            });
              
            if(email && !emailError) { 
                setFormState({ 
                    ...formState,
                    emailError2: isValidEmail(email) ? false : true 
                });
            }
            return false;
       }
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
       
        const validForm = isValidForm(formState);

        if(validForm) {
            setFormState({
                ...formState,
                formValid: true
            })
            send(
                'service_glfazl3',
                'template_wj4x59q',
                formState,
                'user_ruPDkkerXTqkUjaNFudTb'
            )
                .then((resp) => {
                    console.log('bieeen', resp.status, resp.text);
                }) 
                .catch((err) => {
                    console.log('fallo', err);
                })
            console.log('es valudoo')
        }else {
            console.log('no validooo');
        }
    };


    const { formNameError, emailError, emailError2, messageError, checkbox, checkboxError, formValid } = formState;

    if(!formValid) {

        return (
            <div className="container-contact-us" id="contact-us">
                <div className="container-contact-us-title">
                    <h1>Contáctanos</h1>
                    <p>Si tienes alguna pregunta no dudes en contactarnos</p>
                    <p>Puedes contactarnos a través del formulario o a través de nuestro correo: </p><a href="mailto:lacuevitaimpresiones@gmail.com">lacuevitaimpresiones@gmail.com</a>
                    <p>Si quieres elegir uno de nuestros diseños no tienes nada más que decírnoslo</p>
                </div>

                    <div className="container-form">
                        <form encType="multipart/form-data" autoComplete="off">
                            <div className="text-areas mb-3">
                                <label htmlFor="exampleFormControlInputName" className="form-label">Nombre</label>
                                <input name="formName" type="text" value={formState.formName} className="form-control" id="exampleFormControlInputName" placeholder="Nombre" onChange={ handleChange } onBlur={ handleBlur }/>
                                { formNameError
                                    ? <div className="alert alert-danger mt-2">Nombre requerido</div>
                                    : ''
                                }
                            </div>
                            <div className="text-areas mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                                <input name="email" type="email" value={ formState.email } className="form-control" id="exampleFormControlInput1" placeholder="nombre@ejemplo.com" onChange={ handleChange } onBlur={ handleBlur }/>
                                { emailError
                                    ? <div className="alert alert-danger mt-2">Email requerido</div>
                                    : ''
                                }
                                {emailError2
                                    ? <div className="alert alert-danger mt-2">Email inválido</div>
                                    : ''
                                }
                            </div>
                            <div className="text-areas mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensaje</label>
                                <textarea name="message" type="text" value={ formState.message } className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={ handleChange } onBlur={ handleBlur }></textarea>
                                { messageError
                                    ? <div className="alert alert-danger mt-2">Mensaje requerido.</div>
                                    : ''
                                }
                            </div>
                            <div className="privacy-policy-container">
                                <div>
                                    <input type="checkbox" name="checkbox" checked={ formState.checkbox } onChange={ handleChange } onClick={ handleBlur }/><span className="privacy-policy"> He leido y acepto la </span>
                                    <Link to="/privacy-policy" className="privacy-policy">
                                        política de privacidad
                                    </Link>
                                    { checkboxError
                                    ? <div className="alert alert-danger mt-2">Debe aceptar la política de privacidad.</div>
                                    : ''
                                    }
                                </div>

                                <button type="button" className="form-btn" onClick={ handleSubmit }>Enviar mensaje</button>
                            </div>
                        </form>
                        
                    </div>
            </div>
            
        );
    } else {
        return ( 
            <div className="thankyou_details">
                <div className="alert alert-success mt-3">Gracias por su mensaje, nos pondremos en contacto contigo pronto.</div>
                <ul className="list-group">
                    <li className="list-group-item">Name: { formState.formName }</li>
                    <li className="list-group-item">Email: { formState.email }</li>
                    <li className="list-group-item">Mensaje: { formState.message }</li>
                </ul>
            </div>
        )
    }
} 

export default ContactUs
