import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <div className="hover">
                        <Link to="/">
                        <a className="navbar-brand" href="#home">La Cuevita Impresiones <i className="navbar-icon fas fa-paint-brush"></i></a>
                        </Link>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/">
                                    <a className="nav-link" href="#home" aria-current="page">Home</a>
                                </Link>
                            </li>    
                        </ul>
                    </div>
                </div>
            </nav>     
        </>
    )
}

export default Navbar
