import React from 'react';
import './style.css';

import images from '../config/assets/images';

const {
    style1,
    style2,
    style3,
    style4,
} = images;

const Style = () => {
    return (
        <div className="container-style">
            <div className="container-style-text">
                <h1>Estos son algunos de nuestros diseños</h1>
                <p>Aunque si lo prefieres puedes enviarnos el tuyo personalizado, una imagen o fotografía</p>
            </div>
            <div className="container-style-images">
                <div className="sub-container-style-images">
                    <img src={ style1 } alt="" className="img-style"/>
                    <p className="text-style">Diseño logo</p>
                </div>
                <div className="sub-container-style-images">
                    <img src={ style2 } alt="" className="img-style"/>
                    <p className="text-style">Diseño Texto</p>
                </div>
                <div className="sub-container-style-images">
                    <img src={ style3 } alt="" className="img-style"/>
                    <p className="text-style">Diseño imagen</p>
                </div>
                <div className="sub-container-style-images">
                    <img src={ style4 } alt="" className="img-style"/>
                    <p className="text-style">Diseño series</p>
                </div>
            </div>
        </div>
    )
}

export default Style
