import React from 'react'
import ContactUs from './ContactUs'
import Footer from './Footer'
import Home from './Home'
import Navbar from './Navbar'
import Products from './Products'

const MainPage = () => {
    return (
        <div>
            <Navbar />
             <Home />
            <Products />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default MainPage
