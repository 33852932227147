import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainPage from './components/MainPage';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path='/' exact component={ MainPage } />
          <Route path='/privacy-policy' component={ PrivacyPolicy } />
        </Switch>
      </Router>
    </>
  );
}

export default App;
