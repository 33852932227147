import React from 'react';
import './products.css';
import Style from './Style';
import ImagesProducts from './ImagesProducts';
import images from '../config/assets/images';

const {
    almohadilla1,
    cojin1,
    cojin2,
    taza1,
    taza2,
    taza3,
    blusa1,
    bolso1,
    bolso2,
    cantimplora1,
    cartel1,
    copa1,
    estuche1,
    gorra1,
    gorra2,
    llavero1
} = images;






const Products = () => {
    return (
        <>
            <div className="container-products" id="products">
                <h1>Echa un vistazo a los productos</h1>

                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link active" id="pills-cojines-tab" data-bs-toggle="pill" data-bs-target="#pills-cojines" type="button" role="tab" aria-controls="pills-cojines" aria-selected="true">Cojines</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-gorras-tab" data-bs-toggle="pill" data-bs-target="#pills-gorras" type="button" role="tab" aria-controls="pills-gorras" aria-selected="false">Gorras</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-tazas-tab" data-bs-toggle="pill" data-bs-target="#pills-tazas" type="button" role="tab" aria-controls="pills-tazas" aria-selected="false">Tazas</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-bolsos-tab" data-bs-toggle="pill" data-bs-target="#pills-bolsos" type="button" role="tab" aria-controls="pills-bolsos" aria-selected="false">Bolsos</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-blusas-tab" data-bs-toggle="pill" data-bs-target="#pills-blusas" type="button" role="tab" aria-controls="pills-blusas" aria-selected="false">Blusas</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-cantimploras-tab" data-bs-toggle="pill" data-bs-target="#pills-cantimploras" type="button" role="tab" aria-controls="pills-cantimploras" aria-selected="false">Cantimploras</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-alfombrillas-tab" data-bs-toggle="pill" data-bs-target="#pills-alfombrillas" type="button" role="tab" aria-controls="pills-alfombrillas" aria-selected="false">Alfombrillas de ratón</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-estuches-tab" data-bs-toggle="pill" data-bs-target="#pills-estuches" type="button" role="tab" aria-controls="pills-estuches" aria-selected="false">Estuches</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-copas-tab" data-bs-toggle="pill" data-bs-target="#pills-copas" type="button" role="tab" aria-controls="pills-copas" aria-selected="false">Copas</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-carteles-tab" data-bs-toggle="pill" data-bs-target="#pills-carteles" type="button" role="tab" aria-controls="pills-carteles" aria-selected="false">Carteles</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="button-tabs nav-link" id="pills-llaveros-tab" data-bs-toggle="pill" data-bs-target="#pills-llaveros" type="button" role="tab" aria-controls="pills-llaveros" aria-selected="false">Llaveros</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-cojines" role="tabpanel" aria-labelledby="pills-cojines-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ cojin1 } 
                            />
                              <ImagesProducts
                                src={ cojin2 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-gorras" role="tabpanel" aria-labelledby="pills-gorras-tab">
                      <ul className="images-container">
                            <ImagesProducts
                                src={ gorra1 } 
                            />
                            <ImagesProducts
                                src={ gorra2 } 
                            />
                      </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-tazas" role="tabpanel" aria-labelledby="pills-tazas-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ taza1 } 
                            />
                            <ImagesProducts
                                src={ taza2 } 
                            />
                            <ImagesProducts
                                src={ taza3 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-bolsos" role="tabpanel" aria-labelledby="pills-bolsos-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ bolso1 } 
                            />
                            <ImagesProducts
                                src={ bolso2 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-blusas" role="tabpanel" aria-labelledby="pills-blusas-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ blusa1 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-cantimploras" role="tabpanel" aria-labelledby="pills-cantimploras-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ cantimplora1 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-alfombrillas" role="tabpanel" aria-labelledby="pills-alfombrillas-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ almohadilla1 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-estuches" role="tabpanel" aria-labelledby="pills-estuches-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ estuche1 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-copas" role="tabpanel" aria-labelledby="pills-copas-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ copa1 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-carteles" role="tabpanel" aria-labelledby="pills-carteles-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ cartel1 } 
                            />
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-llaveros" role="tabpanel" aria-labelledby="pills-llaveros-tab">
                        <ul className="images-container">
                            <ImagesProducts
                                src={ llavero1 } 
                            />
                        </ul>
                    </div>
                </div>
            </div>


            <div className="style-container">
                <Style />
            </div>
        </>
    )
}

export default Products
