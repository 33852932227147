import React from 'react';
import './home.css';
import images from '../config/assets/images';

const {
    logo
} = images;

const Home = () => {
    return (
        <>
        <div className="container-home" id="home">
            <div className="container-home-text">
                <p className="text-brand">La Cuevita Impresiones</p>
                <h1>Sublima todo lo que quieras donde quieras</h1>
                <p className="text">Haz tus propios diseños y crea productos únicos y personalizables</p>
            </div>
            <div className="container-image">
                <img className="logo-image" src={ logo } alt="logo"/>
            </div>
        </div>
            
        </>
    )
}

export default Home
