import React from 'react';
import './footer.css';
import images from '../config/assets/images';

const {
    logoMariaAngelesmt
} = images;

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="container-social-media">
                <a href="tel:034609014273" className="social-media-icon">
                    <i className='bx bxs-phone'></i>
                </a>
                <a href="mailto:lacuevitaimpresiones@gmail.com" className="social-media-icon">
                    <i className='bx bx-mail-send' ></i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100061004116014" target="_blank" className="social-media-icon">
                    <i className='bx bxl-facebook' ></i>
                </a>
                <a href="https://www.instagram.com/la_cuevita_impresiones?utm_medium=copy_link" target="_blank" className="social-media-icon">
                    <i className='bx bxl-instagram-alt' ></i>
                </a>
            </div>
            <div className="text-footer">
                <small>Copyright © 2021 La Cuevita Impresiones </small>
                <small>desarrollado por <a className="developer" href="https://www.mariaangelesmt.com/" target="_blank">mariaangelesmt </a><img className="logo-angy" alt="logo Maria Angeles" src={ logoMariaAngelesmt }/></small>
            </div>
        </div>
    )
}

export default Footer
